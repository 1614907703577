#root {
  height: 100vh;
  width: 100vw;
}

.app {
  text-align: center;
  width: 30rem;
  height: 30rem;
  margin: auto;
}

.title {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

}

.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  margin-top: 1rem;
  grid-gap: 1px;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  background-color: #eee;
  align-items: center
}

.square {
  all: unset;
  height: 100%;
  width: 100%;
  border: 2px solid #ccc;
  background-color: #eee;
}

.letter {
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 5rem;
  height: 100%;
  color: rgb(75, 74, 74);
  /* animation: jello-horizontal 2s both; */
  animation: pop 0.3s linear 1;
}

.input {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1px;
  padding-top: 1rem;
}

.reset {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1px;
  padding-top: 1rem;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    border-color: #ddd;
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    border-color: #333;
  }
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pop {
  50% {
    transform: scale(1.5);
  }
}